import React from "react"
import loadableVisibility from "react-loadable-visibility/loadable-components"
import HomeBanner from "../components/homepage/HomeBanner"
import Layout from "../components/Layout"
import HomeCaseResults from "../components/homepage/HomeCaseResults"
import HomeFreeConsultation from "../components/homepage/HomeFreeConsultation"
import { Wrapper, Line, Line2 } from "../components/Banner"
import { InteriorContent } from "../components/InteriorContent"
import { LineBottom, HeaderSection } from "../components/PageElements"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Global, css } from "@emotion/core"
import { Helmet } from "react-helmet"
import favicon from "../images/favicon.png"
import Img from "gatsby-image"
import underline from "../images/underline.png"
import VideoPopup from "../components/VideoPopup"
import { RedRectangularButton } from "../components/Buttons"
import styled from "@emotion/styled"

const HomeFeaturedAccolades = loadableVisibility(
  () => import(`../components/homepage/HomeFeaturedAccolades`),
  { fallback: <div>Loading... (rendered from the server)</div> }
)

const SlipFallAttorneysPA = ({ data }) => {
  const cheerio = require("cheerio")
  const page = data.wpgraphql.page

  const $ = cheerio.load(`${page.content}`)
  const h3 = $("h3").text()
  const h1 = $("h1").text()
  const firstParagraph = $("p:nth-of-type(3)").text()
  const restOfContent = $(`${page.content}`).not(
    `.banner-subtitle-1, .banner-title, .banner-subtitle-2, h3, h1,  .sc-bwzfXH.row.uTxCW`
  )

  const breakpoints = useBreakpoint()
  const {
    title,
    metaDesc,
    opengraphTitle,
    twitterDescription,
    twitterTitle,
  } = page.seo

  const RedRectangularButtonStyled = styled(RedRectangularButton)`
    margin-left: 26px;
    margin-right: 26px;
    @media (min-width: 768px) {
      width: 20%;
      margin: 40px auto 0 auto;
    }
  `
  const RedRectangularButtonStyled2 = styled(RedRectangularButton)`
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 40px;
    @media (min-width: 768px) {
      width: 20%;
      margin: 40px auto;
    }
  `
  const StyledGoogleReviews = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  `
  const ContentWrapper = styled.div`
    @media (min-width: 768px) {
      margin-top: 50%;
    }
    @media (min-width: 1600px) {
      margin-top: 30%;
    }
  `
  return (
    <Layout>
      <Global
        styles={css`
          ${InteriorContent} div:nth-of-type(3) p:nth-of-type(1) {
            display: none;
          }
          ${InteriorContent} div.content p {
            display: block !important;
          }
          img {
            max-width: 100%;
          }
        `}
      />
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{opengraphTitle ? opengraphTitle : title}</title>
        <meta name="description" content={metaDesc ? metaDesc : ""} />
        {page.seo.opengraphImage !== null && (
          <meta name="image" content={page.seo.opengraphImage.mediaItemUrl} />
        )}
        <meta
          property="og:url"
          content={`https://www.mmklawfirm.com/${page.slug}`}
        />
        <meta
          property="og:title"
          content={opengraphTitle ? opengraphTitle : title}
        />
        <meta property="og:description" content={metaDesc ? metaDesc : ""} />
        {page.seo.opengraphImage !== null && (
          <meta
            property="og:image"
            content={page.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://www.mmklawfirm.com/${page.slug}`}
        />
        <meta
          name="twitter:title"
          content={
            twitterTitle
              ? twitterTitle
              : opengraphTitle
              ? opengraphTitle
              : title
          }
        />
        <meta
          name="twitter:description"
          content={
            twitterDescription ? twitterDescription : metaDesc ? metaDesc : ""
          }
        />
        {page.seo.opengraphImage != null ||
          (page.seo.twitterImage != null && (
            <meta
              name="twitter:image"
              content={
                page.seo.twitterImage
                  ? page.seo.twitterImage.mediaItemUrl
                  : page.seo.opengraphImage
                  ? page.seo.opengraphImage.mediaItemUrl
                  : ""
              }
            />
          ))}
      </Helmet>
      <HomeBanner title={page.title} />
      <div
        style={{ backgroundColor: "#fff", zIndex: 10, position: "relative" }}
      >
        <StyledGoogleReviews
          href="https://www.google.com/maps/place/Mednick,+Mezyk+%26+Kredo,+PC/@40.0930132,-75.1393567,17z/data=!3m1!5s0x89c3db446af6f85b:0x43cc9a3c6e87a17d!4m8!3m7!1s0x89c6b0be9cee03e5:0x48ddbd0da3e3739c!8m2!3d40.0930132!4d-75.1367818!9m1!1b1!16s%2Fg%2F11bzrkcnbq?entry=ttu"
          target="_blank"
        >
          <p>100+ 5 Star Reviews</p>
          <img
            src="https://backend-gatsby.mmklawfirm.com/wp-content/uploads/2023/12/google-verified-reviews2-1.png"
            loading="lazy"
            width="150"
            height="75"
            alt="5 star reviews on Google"
          />
        </StyledGoogleReviews>
        <RedRectangularButtonStyled href="tel:2155451870">
          Call Now for Help!
        </RedRectangularButtonStyled>
        <HomeFeaturedAccolades />
        <RedRectangularButtonStyled2 to="/#homeForm">
          Get Your Free Consultation
        </RedRectangularButtonStyled2>
        <HomeCaseResults />
        <HomeFreeConsultation />
        <Wrapper>
          <ContentWrapper>
            <InteriorContent>
              <HeaderSection>
                <h3>{h3}</h3>
                <h1>{h1}</h1>
                <LineBottom>
                  {breakpoints.sm ? (
                    <>
                      <Img fluid={data.file.childImageSharp.fluid} />
                    </>
                  ) : (
                    <img className="underline" src={underline} />
                  )}
                </LineBottom>
              </HeaderSection>
              <p dangerouslySetInnerHTML={{ __html: firstParagraph }} />
              {page.title !== "Maggie Grasso" && <VideoPopup />}

              <div dangerouslySetInnerHTML={{ __html: restOfContent }} />
            </InteriorContent>
          </ContentWrapper>
        </Wrapper>
      </div>
    </Layout>
  )
}

export default SlipFallAttorneysPA
export const query = graphql`
  query {
    wpgraphql {
      page(id: "cG9zdDo1MzAz") {
        title
        content
        slug
        seo {
          title
          metaDesc
          opengraphTitle
          twitterDescription
          twitterTitle
          opengraphImage {
            mediaItemUrl
          }
          twitterImage {
            mediaItemUrl
          }
        }
      }
    }
    file(relativePath: { eq: "underline.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
        fixed(width: 660, height: 18, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
